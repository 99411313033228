/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */
import * as microsoftTeams from '@microsoft/teams-js';
import { LayoutContextProvider, frontoffice, isEdelvivesMX, isInPublisher, isNativeAppWebview } from '_core';
import { getApiHealth } from '_core/crud/appInfo.crud';
import * as auth from '_core/store/auth.duck';
import * as coreEntities from '_core/store/index';
import * as routerHelpers from '_core/utils/RouterHelpers';
import { isTeams } from '_core/utils/teamsTab';
import { isEditorial, isStudent, isTeacher } from '_core/utils/user';
import amplitude from 'amplitude-js';
import { clientIdAmplitude } from 'app/config/config';
import DemoLoading from 'app/pages/DemoLandingPage/DemoLoading';
import { notificationsTypes } from 'app/utils/notifications';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { defaultConfig } from '../config/environment';
import AboutView from '../modules/views/AboutView/AboutView';
import SupportView from '../modules/views/SupportView/SupportView';
import PrivacyPolicy from '../pages/StaticPages/PrivacyPolicy';
import TermsOfUse from '../pages/StaticPages/TermsOfUse';
import TabAuthEnd from '../pages/Teams/TabAuthEnd';
import TabAuthStart from '../pages/Teams/TabAuthStart';
import LoginPage from '../pages/auth/Login/LoginPage';
import SignUpPage from '../pages/auth/SignUp/SignUpPage';

const ErrorView = React.lazy(() => import('app/modules/views/ErrorView/ErrorView'));
const NotFound = React.lazy(() => import('app/pages/ContentNotFoundPage/ContentNotFoundPage'));
const BarMenuContainer = React.lazy(() => import('app/containers/BarMenuContainer'));
const HomePage = React.lazy(() => import('app/pages/HomePage/HomePage'));
const KanbanPage = React.lazy(() => import('app/pages/KanbanPage/KanbanPage'));
const CoursePage = React.lazy(() => import('app/pages/CoursePage/CoursePage'));
const TasksStudentView = React.lazy(() => import('app/modules/views/TasksView/TasksStudentView/TasksStudentView'));
const LessonView = React.lazy(() => import('_core/lite/views/LessonView/LessonView/LessonView'));
const CustomLessonView = React.lazy(() => import('app/modules/views/CustomLessonView/CustomLessonView'));
const CustomLessonViewToDigest = React.lazy(() => import('app/modules/views/CustomLessonView/CustomLessonViewToDigest'));
const TasksView = React.lazy(() => import('app/modules/views/TasksView/TasksView'));
const LogoutPage = React.lazy(() => import('../modules/containers/Logout'));
const ToolsProjectContainer = React.lazy(() => import('_core/lite/views/ToolsProjectView/ToolsProjectContainer'));
const AnswerTest = React.lazy(() => import('../pages/AnswerTest'));
const AssessmentResult = React.lazy(() => import('../pages/AssessmentResultPage/AssessmentResult'));
const TokenCallback = React.lazy(() => import('../pages/auth/TokenCallback'));
const CalendarPage = React.lazy(() => import('../pages/CalendarPage/CalendarPage'));
const LemonTest = React.lazy(() => import('../pages/LemonTest'));
const NewAssessment = React.lazy(() => import('../pages/NewAssessment'));
// const NewContentView = React.lazy(() => import('../pages/NewContent'));
const ProfilePage = React.lazy(() => import('../pages/ProfilePage/ProfilePage'));
const ProjectionMint = React.lazy(() => import('../pages/ProjectionMint'));
const Projection = React.lazy(() => import('../pages/Projection'));
const RemotePage = React.lazy(() => import('../pages/RemotePage'));
const Viewer = React.lazy(() => import('../pages/viewer'));
const AddLicense = React.lazy(() => import('../pages/AddLicensePage/AddLicensePage'));
const ResetPasswordPage = React.lazy(() => import('../pages/ResetPasswordPage/ResetPasswordPage'));
const RecoverPasswordPage = React.lazy(() => import('../pages/RecoverPasswordPage/RecoverPasswordPage'));
const ClassroomUsersPage = React.lazy(() => import('../pages/ClassroomUsersPage/ClassroomUsersPage'));
const GroupResourcesPage = React.lazy(() => import('../pages/GroupResourcesPage/GroupResourcesPage'));
const MSTeamsConfiguration = React.lazy(() => import('../pages/MSTeamsConfiguration'));
const IndividualActivityPage = React.lazy(() => import('../pages/IndividualActivityPage/IndividualActivityPage'));
const DeepLinkView = React.lazy(() => import('_core/lite/views/DeepLinkView'));
const DialogDesktopVersion = React.lazy(() => import('_core/modules/components/dialogs/DialogDesktopVersion/DialogDesktopVersion'));
// const DemoLandingPage = React.lazy(() => import('app/pages/DemoEsoLandingPage/DemoEsoLandingPage'));
const CreateEvaluationsPage = React.lazy(() => import('../pages/CreateEvaluationsPage'));
const DoEvaluationPage = React.lazy(() => import('../pages/DoEvaluationPage'));
const ViewEvaluationPage = React.lazy(() => import('../pages/ViewEvaluationPage'));
const ResultsEvaluationPage = React.lazy(() => import('../pages/ResultsEvaluationPage'));
const UiPage = React.lazy(() => import('../pages/UiPage/UiPage'));
const ResourcesPage = React.lazy(() => import('app/pages/ResourcesPage/ResourcesPage'));

export const APP_IS_PUBLISHER = isInPublisher(defaultConfig) || false;

let callbackToken = null;

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  const dispatch = useDispatch();
  const [teamsContentUrl, setTeamsContentUrl] = useState('');
  const [isTeamsDark, setIsTeamsDark] = useState(false);

  checkIfCallbackTokenExists();

  const { isAuthorized, country, roleUser, license, isChangePassword } = useSelector(
    ({ auth }) => ({
      isAuthorized: callbackToken ? auth.user != null && auth.authToken === callbackToken : auth.user != null,
      roleUser: (auth.user && auth.user.role_guid) || null,
      country: auth.user && auth.user.country_guid ? auth.user.country_guid : 'es',
      license: auth.license,
      isChangePassword: Boolean(auth.changePassword),
    }),
    shallowEqual
  );

  const user = useSelector((state) => auth.selectors.getUser(state));
  const oauthAccounts = useSelector((state) => auth.selectors.getOauthAccounts(state));
  const lms = useSelector((state) => coreEntities.organization.selectors.getLms(state));
  const client = useSelector((state) => coreEntities.organization.selectors.getClient(state));
  // const tenant = useSelector((state) => coreEntities.organization.selectors.getTenant(state));

  useEffect(() => {
    if (isEdelvivesMX()) dispatch(coreEntities.i18n.actions.setLanguage('es-MX'));
  }, []);

  // useEffect(() => {
  //   if (user?.guid && tenant?.guid) {
  //     initUserForTracking(user, tenant.guid);
  //   }
  // }, [user, tenant]);

  useEffect(() => {
    checkApiHealth();
    if (isAuthorized && client) {
      dispatch(frontoffice.notifications.actions.getNotifications({ offset: 0, pageSize: 4, typeNotifications: notificationsTypes.SOCIAL }));
      dispatch(
        frontoffice.notifications.actions.getNotificationsToDo({
          offset: 0,
          pageSize: 4,
          typeNotifications: notificationsTypes.TODO,
        })
      );
      dispatch(frontoffice.notifications.actions.getNotificationsUnread());
      dispatch(frontoffice.notifications.actions.getNotificationsUnreadToDo());
      dispatch(frontoffice.learningObjectives.actions.getLearningObjectives());
      dispatch(frontoffice.educationLevels.actions.getEducationLevels({ country, client: client }));
    }
    // dispatch(contentsCourses.actions.getCourses());
  }, [isAuthorized, client]);

  useEffect(() => {
    if (!lms) {
      dispatch(coreEntities.organization.actions.getOrganization());
      dispatch(frontoffice.langs.actions.getLangs({ isFilter: true }));
    }
  }, [lms]);

  useEffect(() => {
    if (client && isAuthorized) {
      let account = oauthAccounts?.filter((acc) => acc.provider !== '');
      let schoolGuid = null;
      let schoolName = null;
      if (user.schools && user.schools.length > 0) {
        schoolGuid = user.schools[0].guid;
        schoolName = user.schools[0].name;
      }
      amplitude.getInstance().init(clientIdAmplitude[window.location.origin], user.guid);
      amplitude.getInstance().setUserProperties({
        client: client,
        role: user.role_name,
        source: isTeams() ? 'MS Teams' : user.source,
        provider: account && account.length > 0 ? account[0].provider : 'user and pass',
        schoolGuid: schoolGuid,
        schoolName: schoolName,
      });
    }
  }, [client, isAuthorized]);

  if (lastLocation && isAuthorized) {
    routerHelpers.saveLastLocation(lastLocation);
  }

  async function checkApiHealth() {
    const response = await getApiHealth();
    if (response?.status !== 200) {
      history.push('/error');
    }
  }

  const theme = useSelector((store) => frontoffice.ui.selectors.getTheme(store));
  const userRoleGuid = useSelector((state) => auth.selectors.getUserRoleGuid(state));
  const language = useSelector((state) => coreEntities.i18n.selectors.getLanguage(state));

  function checkIfCallbackTokenExists() {
    const path = window.location.pathname;
    // We check this in case it comes from the backoffice and we are already logged in with another user
    if (path.includes('/auth/login/')) {
      const splitted = path.split('/');
      callbackToken = splitted.length > 4 ? splitted[splitted.length - 2] : splitted[splitted.length - 1];
    }
    if (path === '/auth/login') {
      callbackToken = null;
    }
  }

  //EXAMPLE OF RESPONSE
  //window.open(`${BASE_URL_}auth/login/${response.data.data.token}/course&${data.guid}&program`);
  //auth/login/${token}/resources`)
  function redirectFromLoginWithToken() {
    const path = window.location.pathname;
    if (path.startsWith('/auth/login/')) {
      const splitted = path.split('/');
      if (splitted.length > 4) {
        const newPath = '/' + splitted[splitted.length - 1].replaceAll('&', '/');
        return newPath;
      }
    }
    return '/home';
  }

  useEffect(() => {
    if (isTeams()) {
      microsoftTeams.app.initialize().then(() => {
        microsoftTeams.app.getContext().then((context) => {
          setIsTeamsDark(context.app.theme === 'dark');
        });
        microsoftTeams.pages.getConfig().then((pageConfig) => {
          if (pageConfig.contentUrl) {
            setTeamsContentUrl(pageConfig.contentUrl.replace(window.location.origin, ''));
          }
        });
      });
    }
  }, []);

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <div
      className={clsx(`theme-${theme}`, 'app-content', `lang-${language}`, {
        'app-content--publisher': isEditorial(userRoleGuid),
        'app-content--student': isStudent(userRoleGuid),
        'app-content--teacher': isTeacher(userRoleGuid),
        'app-content--msteams': isTeams(),
        'app-content--msteams-dark': isTeamsDark,
        'app-content--native-app': isNativeAppWebview(),
      })}
    >
      <LayoutContextProvider history={history} menuConfig={{}}>
        {userRoleGuid && isEditorial(userRoleGuid) && <BarMenuContainer />}
        <Switch>
          <Route path="/remote/:shortToken" component={RemotePage} />
          <Route path="/deeplink" render={() => <DeepLinkView />} />
          <Route path="/auth/login/:token/deeplink" render={() => <DeepLinkView />} />
          <Route path="/about" render={() => <AboutView />} />
          <Route path="/support" render={() => <SupportView />} />

          {/* Teams configuration is required regardless if authorized or not to be able to bypass the popup */}
          <Route path="/config" component={MSTeamsConfiguration} />
          <Route path="/tab-auth-start" component={TabAuthStart} />
          <Route path="/tab-auth-end" component={TabAuthEnd} />
          {/* Static pages */}
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/terms" component={TermsOfUse} />
          <Route path="/logout" component={LogoutPage} />
          {/* <Route path="/demo" component={DemoLandingPage} /> */}
          <Route path="/loadingDemo" component={DemoLoading} />
          <Route path="/preschool-resources" render={() => <ResourcesPage />} />

          {!isAuthorized && (
            /* Redirect to `/auth` when user is not authorized */
            <Switch>
              <Route path="/auth/login/:token" component={TokenCallback} />
              <Route path="/auth/login" component={LoginPage} />
              <Route path="/auth/signup" component={SignUpPage} />
              <Route path="/recover-password" component={RecoverPasswordPage} />
              <Redirect to="/auth/login" />
            </Switch>
          )}

          {((isAuthorized && !isChangePassword && license) || roleUser === 'R04') && (
            <Switch>
              <Redirect exact from="/" to="/home" />
              {isTeams() && <Redirect exact from="/" to={teamsContentUrl} />}
              {isTeams() && <Redirect exact from="/home" to={teamsContentUrl} />}
              <Route path="/home" render={() => <HomePage />} />
              {window.origin.includes('localhost') && <Route path="/ui" render={() => <UiPage />} />}
              <Route
                path="/course/:courseGuid/lesson/:lessonGuid/assessment/:assessmentGuid/question/:contentGuid"
                render={(props) => <LemonTest {...props} />}
              />
              <Route
                path="/course/:courseGuid/lesson/:lessonGuid/assessment/:assessmentGuid/new-question/:lemonadeType"
                render={(props) => <LemonTest {...props} />}
              />
              <Route path="/course/:courseGuid/lesson/:lessonGuid/results-assessment/:assessmentGuid" render={(props) => <AssessmentResult {...props} />} />
              <Route
                path="/course/:courseGuid/lesson/:lessonGuid/assessment/:assessmentGuid"
                render={(props) => (userRoleGuid === 'R01' ? <AnswerTest {...props} /> : <NewAssessment {...props} />)}
              />
              {/* <Route path="/course/:courseGuid/lesson/:lessonGuid/new-content" render={(props) => <NewContentView {...props} />} /> */}
              {/* <Route path="/course/:courseGuid/lesson/:lessonGuid/content/:contentGuid" render={(props) => <NewContentView {...props} />} /> */}
              <Route path="/course/:courseGuid/lesson/:lessonGuid/projection/:unitGuid" render={() => <ProjectionMint />} />
              <Route path="/course/:courseGuid/lesson/:lessonGuid/projection" render={() => <Projection />} />
              <Route path="/course/:courseGuid/lesson/:lessonGuid" render={() => <LessonView />} />
              <Route
                path="/course/:courseGuid/unit/:unitGuid/lesson/:lessonGuid/digest"
                render={(props) => <CustomLessonViewToDigest {...props} typeToFilterLesson={isTeams() ? 'mint' : null} />}
              />
              <Route
                path="/course/:courseGuid/unit/:unitGuid/lesson/:lessonGuid"
                render={(props) => <CustomLessonView {...props} typeToFilterLesson={isTeams() ? 'mint' : null} />}
              />
              <Route
                path="/course/:courseGuid/unit/:unitGuid"
                render={(props) => <CustomLessonView {...props} typeToFilterLesson={isTeams() ? 'mint' : null} />}
              />
              <Route
                path="/course/:courseGuid/create-evaluations/:idResourcesSection/:idResourcesGroup"
                render={(props) => <CreateEvaluationsPage {...props} />}
              />
              <Route
                path="/course/:courseGuid/edit-evaluations/:idResourcesSection/:idResourcesGroup/:contentGuid"
                render={(props) => <CreateEvaluationsPage {...props} />}
              />

              <Route
                path="/course/:guid/resources/:idResourcesSection/:idResourcesGroup/evaluation/:evaluationGuid/:contentGuid"
                render={(props) => <DoEvaluationPage {...props} />}
              />
              <Route
                path="/course/:guid/resources/:idResourcesSection/:idResourcesGroup/view-evaluation/:evaluationGuid/:contentGuid"
                render={(props) => <ViewEvaluationPage {...props} />}
              />
              <Route
                path="/course/:guid/resources/view-evaluation/:evaluationGuid/:contentGuid/user/:userGuid"
                render={(props) => <ViewEvaluationPage {...props} />}
              />

              <Route
                path="/course/:guid/resources/:idResourcesSection/:idResourcesGroup/view-results/:evaluationGuid/:contentGuid"
                render={(props) => <ResultsEvaluationPage {...props} />}
              />

              <Route path="/course/:guid/resources/view-results/:evaluationGuid/:contentGuid" render={(props) => <ResultsEvaluationPage {...props} />} />

              <Route path="/course/:guid/kanban/:lessonGuid" render={() => <KanbanPage />} />
              <Route path="/course/:guid/grades" render={() => <CoursePage tabId="grades" />} />
              <Route path="/course/:guid/program" render={() => <CoursePage tabId="program" />} />
              <Route path="/course/:guid/resources/:idResourcesSection/:idResourcesGroup" render={() => <GroupResourcesPage />} />
              <Route path="/course/:guid/resources" render={() => <CoursePage tabId="resources" />} />
              <Route path="/course/:guid/resources-demo" render={() => <CoursePage tabId="resources-demo" />} />
              <Route path="/course/:guid" render={() => <CoursePage tabId="stream" />} />
              <Route path="/viewerfull/:guid" render={(props) => <Viewer {...props} showHeader={false} />} />
              <Route path="/viewer/:guid/:className" render={(props) => <Viewer {...props} showHeader={true} />} />
              <Route path="/viewer/:guid" render={(props) => <Viewer {...props} showHeader={true} />} />
              <Route path="/calendar" render={() => <CalendarPage />} />
              <Route path="/calendar/:course" render={(props) => <CalendarPage {...props} />} />
              <Route path="/profile" render={() => <ProfilePage />} />
              <Route path="/tasks" render={() => (userRoleGuid === 'R01' ? <TasksStudentView /> : <TasksView />)} />
              <Route path="/lemon-test" render={() => <LemonTest />} />
              <Route path="/answer-test" render={() => <AnswerTest />} />
              <Route path="/single-activity/:courseGuid/record/:contentGuid/:referenceGuid" render={() => <IndividualActivityPage record={true} />} />
              <Route path="/single-activity/:courseGuid/:lessonItemGuid/:contentGuid" render={() => <IndividualActivityPage />} />
              <Route path="/classroom/:groupGuid/users" render={() => <ClassroomUsersPage />} />
              <Route path="/tools/:courseProjectGuid" render={() => <ToolsProjectContainer />} />
              <Route path="/remote" component={RemotePage} />
              <Route path="/error" component={ErrorView} />

              <Route path="/license" component={AddLicense} />
              <Route path="/desktop" render={() => <DialogDesktopVersion />} />
              <Redirect from="/auth/login/:token" to={redirectFromLoginWithToken()} />
              <Redirect from="/recover-password" to={'/home'} />
              <Redirect from="/auth" to={'/home'} />
              <Redirect from="/tab" to="home" />
              <Route path="*" component={NotFound} />
            </Switch>
          )}

          {isAuthorized && isChangePassword && (
            <Switch>
              <Route path="/reset-password" component={ResetPasswordPage} />
              <Redirect to="/reset-password" />
            </Switch>
          )}

          {isAuthorized && !license && (
            <Switch>
              <Route path="/license" component={AddLicense} />
              <Redirect to="/license" />
            </Switch>
          )}
        </Switch>
      </LayoutContextProvider>
    </div>
  );
});
