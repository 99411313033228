import { removeAllStorage } from '_core';

export function isTeacher(user) {
  return user.role_guid === 'R02' || user === 'R02';
}

export function isStudent(user) {
  return user.role_guid === 'R01' || user === 'R01';
}

export function isEditorial(user) {
  return (user && user.role_guid === 'R04') || user === 'R04';
}

export function redirectUserIfWrongDomain(user, token) {
  if (user?.country_guid === 'es' && window.location.host === 'edelvivesdigitalplus.com.mx') {
    removeAllStorage();
    window.location.href = 'https://edelvivesdigitalplus.com/auth/login/' + token;
  }
  if (user?.country_guid === 'es' && window.location.host === 'edelvivesdigital-mx-dev.oneclicklabs.es') {
    removeAllStorage();
    window.location.href = 'https://edelvivesdigital-dev.oneclicklabs.es/auth/login/' + token;
  }
  if (user?.country_guid === 'mx' && window.location.host === 'edelvivesdigitalplus.com') {
    removeAllStorage();
    window.location.href = 'https://edelvivesdigitalplus.com.mx/auth/login/' + token;
  }
  if (user?.country_guid === 'mx' && window.location.host === 'edelvivesdigital-dev.oneclicklabs.es') {
    removeAllStorage();
    window.location.href = 'https://edelvivesdigital-mx-dev.oneclicklabs.es/auth/login/' + token;
  }
}
