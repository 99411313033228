import { createLicense, deleteLicense, getLicenses, updateLicense } from '_core/crud/licenses.crud';
import { put, takeLatest } from 'redux-saga/effects';

export const actionTypes = {
  GetLicenses: 'license/GET_LICENSES',
  SetLicenses: 'license/SET_LICENSES',
  CreateLicense: 'license/CREATE_LICENSE',
  AddOneLicense: 'license/ADD_ONE_LICENSE',
  StatusUpsert: 'license/ERROR_UPSERT_LICENSE',
  DeleteLicense: 'license/DELETE_LICENSE',
  RemoveLicense: 'license/REMOVE_LICENSE',
  UpdateLicense: 'license/UPDATE_LICENSE',
  ReplaceLicense: 'license/REPLACE_LICENSE',
};

const initialState = {
  loading: false,
  statusUpsert: null,
  currentEdit: {},
  licensesForSearch: {
    left: 0,
    length: 0,
    offset: 0,
    pageSize: 0,
    data: [],
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetLicenses: {
      return { ...state, loading: true };
    }

    case actionTypes.SetLicenses: {
      const { response } = action.payload;

      if (response && response.status === 'success' && response.data) {
        let newLicensesForSearch = [...response.data.personalLicenses];

        if (response.data.offset > 0) {
          newLicensesForSearch = [...state.licensesForSearch.data, ...response.data.personalLicenses];
        }

        return {
          ...state,
          licensesForSearch: {
            orderBy: 'created_at desc',
            left: response.data.left,
            length: response.data.total,
            offset: response.data.offset,
            pageSize: response.data.pageSize,
            data: newLicensesForSearch,
          },
          loading: false,
        };
      }
      return state;
    }

    case actionTypes.CreateLicense: {
      return { ...state, loading: true, statusUpsert: null };
    }

    case actionTypes.AddOneLicense: {
      const { params } = action.payload;
      if (params && params.guid) {
        let data = Object.values(state['licensesForSearch'].data).map((val) => {
          return val;
        });
        data.push(params);
        return {
          ...state,
          licensesForSearch: {
            ...state['licensesForSearch'],
            data: data,
          },
          statusUpsert: 200,
          loading: false,
        };
      } else {
        return { ...state, statusUpsert: 200, loading: false };
      }
    }

    case actionTypes.StatusUpsert: {
      return { ...state, statusUpsert: action.payload };
    }

    case actionTypes.DeleteLicense: {
      return { ...state, statusUpsert: null };
    }

    case actionTypes.RemoveLicense: {
      const { guid } = action.payload;
      if (guid) {
        let licensesResultAll = state['licensesForSearch'].data.filter((d) => {
          return d.guid !== guid[0];
        });
        return {
          ...state,
          licensesForSearch: {
            ...state['licensesForSearch'],
            data: licensesResultAll,
          },
          statusUpsert: 200,
          loading: false,
        };
      }
      return { ...state };
    }

    case actionTypes.UpdateLicense: {
      return { ...state, statusUpsert: null };
    }

    case actionTypes.ReplaceLicense: {
      const { params } = action.payload;
      if (params) {
        let licensesResultAll = state['licensesForSearch'].data.map((d) => {
          if (d.guid === params.guid) {
            return params;
          }
          return d;
        });
        return {
          ...state,
          licensesForSearch: {
            ...state['licensesForSearch'],
            data: licensesResultAll,
          },
          statusUpsert: 200,
          loading: false,
        };
      }

      return { ...state };
    }

    default:
      return state;
  }
};

const getUpsertStatus = (state) => (state.entities ? state.entities.licenses.statusUpsert : false);

export const selectors = {
  getLicenses: (state) => {
    return state.entities && state.entities.licenses ? state.entities.licenses.licensesForSearch : null;
  },
  getLoading: (state) => {
    return state.entities && state.entities.licenses ? state.entities.licenses.loading : null;
  },
  // se repiten 2 y no se cual es el bueno
  getUpsertStatus: (state) => {
    return (state.entities && state.entities.licenses.statusUpsert) || false;
  },
  // getUpsertStatus: createSelector([getUpsertStatus], (upsertStatus) => {
  //   return {
  //     upsertStatus,
  //   };
  // }),
};

export const actions = {
  getLicenses: (params) => ({ type: actionTypes.GetLicenses, payload: { params } }),
  setLicenses: (response) => ({ type: actionTypes.SetLicenses, payload: { response } }),
  createLicense: (params) => ({ type: actionTypes.CreateLicense, payload: { params } }),
  addOneLicense: (params) => ({ type: actionTypes.AddOneLicense, payload: { params } }),
  deleteLicense: (params) => ({ type: actionTypes.DeleteLicense, payload: { params } }),
  removeLicense: (params) => ({ type: actionTypes.RemoveLicense, payload: params }),
  updateLicense: (params) => ({ type: actionTypes.UpdateLicense, payload: { params } }),
  replaceLicense: (params) => ({ type: actionTypes.ReplaceLicense, payload: { params } }),
  setStatusUpsert: (params) => ({ type: actionTypes.StatusUpsert, payload: params }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetLicenses, function* getLicensesSaga(action) {
    const { data, error } = yield getLicenses(action.payload.params);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.setLicenses(data));
    } else {
      const status = (error && error.data && error.data.error) || 400;
      yield put(actions.setStatusUpsert(status));
    }
  });

  yield takeLatest(actionTypes.CreateLicense, function* createLicenseSaga(action) {
    const { data, error } = yield createLicense(action.payload.params);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.addOneLicense(data.data));
    }
  });

  yield takeLatest(actionTypes.DeleteLicense, function* deleteLicenseSaga(action) {
    const { data } = yield deleteLicense(action.payload.params);
    if (data && data.status === 'success') yield put(actions.removeLicense(action.payload.params));
  });

  yield takeLatest(actionTypes.UpdateLicense, function* updateLicenseSaga(action) {
    const { data } = yield updateLicense(action.payload.params);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.replaceLicense(data.data));
    }
  });
}
