import { getEvaUsers } from '_core/crud/users.crud';
import { put, takeLatest } from 'redux-saga/effects';

export const actionTypes = {
  GetEvaUsers: 'cms/GET_EVA_USERS',
  SetEvaUsers: 'cms/SET_EVA_USERS',
};

const initialState = {
  loading: false,
  usersForSearch: {
    left: 0,
    length: 0,
    offset: 0,
    pageSize: 0,
    data: [],
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetEvaUsers: {
      return { ...state, loading: true };
    }

    case actionTypes.SetEvaUsers: {
      const { response } = action.payload;

      if (response && response.status === 'success' && response.data) {
        let newUsersForSearch = [...response.data.users];

        if (response.data.offset > 0) {
          newUsersForSearch = [...state.usersForSearch.data, ...response.data.users];
        }

        return {
          ...state,
          usersForSearch: {
            left: response.data.left,
            length: response.data.total,
            offset: response.data.offset,
            pageSize: response.data.pageSize,
            data: newUsersForSearch,
          },
          loading: false,
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export const selectors = {
  getUsers: (state) => {
    return state.entities && state.entities.users ? state.entities.users.usersForSearch : null;
  },
  getLoading: (state) => {
    return state.entities && state.entities.users ? state.entities.users.loading : null;
  },
};

export const actions = {
  getEvaUsers: (params) => ({ type: actionTypes.GetEvaUsers, payload: { params } }),
  setEvaUsers: (response) => ({ type: actionTypes.SetEvaUsers, payload: { response } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetEvaUsers, function* getEvaUsersSaga(action) {
    const { data } = yield getEvaUsers(action.payload.params);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.setEvaUsers(data));
    }
  });
}
