import { frontoffice } from '_core';
import { getApiHealth } from '_core/crud/appInfo.crud';
import * as auth from '_core/store/auth.duck';
import * as coreEntities from '_core/store/index';
import { isTeams } from '_core/utils/teamsTab';
import { getRoleUserString } from '_core/utils/user';
import amplitude from 'amplitude-js';
import { DEFAULT_LANG, clientIdAmplitude } from 'app/config/config';
import { notificationsTypes } from 'app/utils/notifications';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

let callbackToken = null;

const AppContainer = () => {
  const user = useSelector((state) => auth.selectors.getUser(state));
  const oauthAccounts = useSelector((state) => auth.selectors.getOauthAccounts(state));
  const lms = useSelector((state) => coreEntities.organization.selectors.getLms(state));
  const client = useSelector((state) => coreEntities.organization.selectors.getClient(state));
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (window.StonlyWidget !== undefined) {
      window.StonlyWidget('enableDebug');
      if (user?.guid) {
        const data = {
          'user-segment': user.is_demo === 1 ? 'demo' : '',
          lang: user.lang_id || DEFAULT_LANG,
          role: getRoleUserString(user),
        };
        window.StonlyWidget('identify', user.guid, data);
      } else {
        window.StonlyWidget('identify', null, { 'user-segment': '' });
      }
    }
  }, [user]);

  const { isAuthorized, country } = useSelector(
    ({ auth }) => ({
      isAuthorized: callbackToken ? auth.user != null && auth.authToken === callbackToken : auth.user != null,
      country: auth.user && auth.user.country_guid ? auth.user.country_guid : 'es',
    }),
    shallowEqual
  );

  async function checkApiHealth() {
    const response = await getApiHealth();
    if (response.hasOwnProperty('error')) {
      history.push('/error');
    }
  }

  useEffect(() => {
    if (isAuthorized && client) {
      checkApiHealth();

      dispatch(frontoffice.notifications.actions.getNotifications({ offset: 0, pageSize: 4, typeNotifications: notificationsTypes.SOCIAL }));
      dispatch(
        frontoffice.notifications.actions.getNotificationsToDo({
          offset: 0,
          pageSize: 4,
          typeNotifications: notificationsTypes.TODO,
        })
      );
      dispatch(frontoffice.notifications.actions.getNotificationsUnread());
      dispatch(frontoffice.notifications.actions.getNotificationsUnreadToDo());
      dispatch(frontoffice.learningObjectives.actions.getLearningObjectives());
      dispatch(frontoffice.educationLevels.actions.getEducationLevels({ country, client: client }));
    }
    // dispatch(contentsCourses.actions.getCourses());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, client]);

  useEffect(() => {
    if (!lms) {
      dispatch(coreEntities.organization.actions.getOrganization());
      dispatch(frontoffice.langs.actions.getLangs({ isFilter: true }));
    }
  }, [lms]);

  useEffect(() => {
    if (client && isAuthorized) {
      let account = oauthAccounts?.filter((acc) => acc.provider !== '');
      let schoolGuid = null;
      let schoolName = null;
      if (user.schools && user.schools.length > 0) {
        schoolGuid = user.schools[0].guid;
        schoolName = user.schools[0].name;
      }
      amplitude.getInstance().init(clientIdAmplitude[window.location.origin], user.guid);
      amplitude.getInstance().setUserProperties({
        client: client,
        role: user.role_name,
        source: isTeams() ? 'MS Teams' : user.source,
        provider: account && account.length > 0 ? account[0].provider : 'user and pass',
        schoolGuid: schoolGuid,
        schoolName: schoolName,
      });
    }
  }, [client, isAuthorized]);

  return <div></div>;
};

export default AppContainer;
