import { isStudent } from '_core/utils/user';
import { ROUTE_TYPES } from '_core/variables/constant';

export const notificationsTypes = {
  TODO: 'toDo',
  SOCIAL: 'social',
};

export const notificationsSubtypes = {
  SOCIAL: {
    COMMENT: 'stream-comment',
    POST: 'stream-post',
    ASSESSMENTCOMMENT: 'assessment-comment',
    JOBCOMMENT: 'job-comment',
    TENANT: 'tenant-post',
  },
  TODO: {
    ASSIGNED: 'assessment-assigned',
    REASSIGNED: 'assessment-reassigned',
    EVALUATED: 'assessment-evaluated',
    ONEDAY: 'due-date-tomorrow',
    LATE: 'due-date-today',
    DELIVERATCOMPLETE: 'teacher-deliver-at-complete',
    PUBLISHPENDING: 'teacher-publish-jobs-pending',
    STUDENTDELIVER: 'teacher-job-delivered',
    ALLDELIVER: 'teacher-all-jobs-completed',
    RESOURCE_ASSIGNED: 'resource-assigned',
    RESOURCE_EVALUATED: 'resource-evaluated',
    RESOURCE_DELIVERED: 'resource-delivered',
    RECORD_DELIVERED: 'record-delivered',
  },
};

export const getTypeOfSubTypeNotification = (subtypeNotification) => {
  if (
    subtypeNotification === notificationsSubtypes.TODO.ASSIGNED ||
    subtypeNotification === notificationsSubtypes.TODO.REASSIGNED ||
    subtypeNotification === notificationsSubtypes.TODO.EVALUATED ||
    subtypeNotification === notificationsSubtypes.TODO.ONEDAY ||
    subtypeNotification === notificationsSubtypes.TODO.LATE ||
    subtypeNotification === notificationsSubtypes.TODO.DELIVERATCOMPLETE ||
    subtypeNotification === notificationsSubtypes.TODO.PUBLISHPENDING ||
    subtypeNotification === notificationsSubtypes.TODO.STUDENTDELIVER ||
    subtypeNotification === notificationsSubtypes.TODO.ALLDELIVER ||
    subtypeNotification === notificationsSubtypes.TODO.RESOURCE_ASSIGNED ||
    subtypeNotification === notificationsSubtypes.TODO.RESOURCE_EVALUATED ||
    subtypeNotification === notificationsSubtypes.TODO.RESOURCE_DELIVERED ||
    subtypeNotification === notificationsSubtypes.TODO.RECORD_DELIVERED
  ) {
    return notificationsTypes.TODO;
  } else if (
    subtypeNotification === notificationsSubtypes.SOCIAL.COMMENT ||
    subtypeNotification === notificationsSubtypes.SOCIAL.POST ||
    subtypeNotification === notificationsSubtypes.SOCIAL.ASSESSMENTCOMMENT ||
    subtypeNotification === notificationsSubtypes.SOCIAL.JOBCOMMENT
  ) {
    return notificationsTypes.SOCIAL;
  }
};

export const isNotificationTypeToDo = (typeNotification) => {
  return (
    typeNotification === notificationsSubtypes.TODO.ASSIGNED ||
    typeNotification === notificationsSubtypes.TODO.REASSIGNED ||
    typeNotification === notificationsSubtypes.TODO.EVALUATED ||
    typeNotification === notificationsSubtypes.TODO.ONEDAY ||
    typeNotification === notificationsSubtypes.TODO.LATE ||
    typeNotification === notificationsSubtypes.TODO.DELIVERATCOMPLETE ||
    typeNotification === notificationsSubtypes.TODO.PUBLISHPENDING ||
    typeNotification === notificationsSubtypes.TODO.STUDENTDELIVER ||
    typeNotification === notificationsSubtypes.TODO.ALLDELIVER ||
    typeNotification === notificationsSubtypes.TODO.RESOURCE_ASSIGNED ||
    typeNotification === notificationsSubtypes.TODO.RESOURCE_EVALUATED ||
    typeNotification === notificationsSubtypes.TODO.RESOURCE_DELIVERED ||
    typeNotification === notificationsSubtypes.TODO.RECORD_DELIVERED
  );
};

export const isNotificationTypeSocial = (typeNotification) => {
  return (
    typeNotification === notificationsSubtypes.SOCIAL.COMMENT ||
    typeNotification === notificationsSubtypes.SOCIAL.POST ||
    typeNotification === notificationsSubtypes.SOCIAL.ASSESSMENTCOMMENT ||
    typeNotification === notificationsSubtypes.SOCIAL.JOBCOMMENT
  );
};

export function isResourceNotification(typeNotification) {
  return typeNotification.includes('resource');
}

export function getRouteToClickInNotification(notification, user) {
  if (isNotificationTypeSocial(notification.type)) {
    return getSocialNotificationsRoute(notification, user);
  } else if (isNotificationTypeToDo(notification.type)) {
    return getTodoNotificationRoute(notification, user);
  }
}

export function getTextNotificationsUnread(notificationsUnread) {
  let text = notificationsUnread;
  if (notificationsUnread >= 10) {
    text = '9+';
  }
  if (notificationsUnread >= 100) {
    text = '99+';
  }
  return text;
}

function getSocialNotificationsRoute(notification, user) {
  const { data } = notification;
  const state = {
    courseGuid: data?.course?.guid,
    lessonGuid: data?.message?.parentMessageData?.lesson_guid || data?.lesson?.guid,
    postGuid: data?.message?.parentMessageData?.lesson_item_guid || data?.message?.parent_guid || data?.message?.guid,
    unitGuid: data?.section?.guid,
    assessmentGuid: data?.lesson_item?.guid,
    contentGuid: data?.content?.guid,
    userGuid: data?.student?.guid,
    comment: '1',
  };

  const routeConfig = {
    [notificationsSubtypes.SOCIAL.ASSESSMENTCOMMENT]: ROUTE_TYPES.LESSON,
    [notificationsSubtypes.SOCIAL.JOBCOMMENT]: isStudent(user) ? ROUTE_TYPES.UNIT : ROUTE_TYPES.RESULTS_ASSESSMENT,
  };

  const pathKey = routeConfig[notification.type] || ROUTE_TYPES.COURSE;

  return { state, pathKey };
}

function getTodoNotificationRoute(notification, user) {
  const { data } = notification;

  const baseState = {
    courseGuid: data?.course?.guid,
    lessonGuid: data?.lesson?.guid || data?.message?.parentMessageData?.lesson_guid,
    contentGuid: data?.content?.guid,
    lessonItemGuid: data?.lesson_item?.guid,
    assessmentGuid: data?.content?.guid || data?.assesment?.guid,
    contentType: data?.content?.type_guid || data?.message?.content?.type_guid,
    resourceType: data?.section?.type,
    section: data?.section?.guid,
    userGuid: data?.user?.guid,
    unitGuid: data?.section?.guid,
  };
  console.log(notification);
  const routeConfig = {
    [notificationsSubtypes.TODO.DELIVERATCOMPLETE]: { pathKey: ROUTE_TYPES.RESULTS_ASSESSMENT, state: { ...baseState, userGuid: null } },
    [notificationsSubtypes.TODO.ALLDELIVER]: { pathKey: ROUTE_TYPES.RESULTS_ASSESSMENT, state: { ...baseState, userGuid: null } },
    [notificationsSubtypes.TODO.PUBLISHPENDING]: { pathKey: ROUTE_TYPES.RESULTS_ASSESSMENT, state: { ...baseState, userGuid: null } },
    [notificationsSubtypes.TODO.STUDENTDELIVER]: { pathKey: ROUTE_TYPES.RESULTS_ASSESSMENT, queryParams: { student: notification?.user?.guid } },
    [notificationsSubtypes.TODO.RECORD_DELIVERED]: {
      pathKey: ROUTE_TYPES.SINGLE_ACTIVITY_RECORD,
      state: { ...baseState, referenceGuid: data?.content?.reference_id },
      queryParams: { student: notification?.user?.guid },
    },
    [notificationsSubtypes.TODO.RESOURCE_ASSIGNED]: getResourceAssignedConfig(data),
    [notificationsSubtypes.TODO.RESOURCE_DELIVERED]: getResourceDeliveredConfig(data),
    [notificationsSubtypes.TODO.RESOURCE_EVALUATED]: getResourceEvaluatedConfig(data),
    [notificationsSubtypes.TODO.ASSIGNED]: getAssignedConfig(data),
    [notificationsSubtypes.TODO.EVALUATED]: getAssignedConfig(data),
  };

  const defaultConfig = {
    pathKey: ROUTE_TYPES.UNIT,
  };

  return { ...defaultConfig, ...routeConfig[notification.type], state: { ...baseState, ...routeConfig[notification.type]?.state } };
}

function getResourceAssignedConfig(data) {
  if (data?.section?.type === 'test') {
    return {
      pathKey: ROUTE_TYPES.RESOURCE_EVALUATION,
      state: {
        idResourcesSection: data?.section?.parent_guid,
        idResourcesGroup: data?.section?.guid,
        evaluationGuid: data?.item?.guid,
      },
    };
  }
  return {};
}

function getResourceDeliveredConfig(data) {
  if (data?.section?.type === 'test') {
    return {
      pathKey: ROUTE_TYPES.VIEW_EVALUATION,
      state: {
        courseGuid: data?.course?.guid,
        contentGuid: data?.content?.guid,
        evaluationGuid: data?.item?.guid,
        userGuid: data?.student?.guid,
      },
    };
  }
  return {};
}

function getResourceEvaluatedConfig(data) {
  if (data?.section?.type === 'test') {
    return {
      pathKey: ROUTE_TYPES.VIEW_RESULTS,
      queryParams: { notification: true },
      state: {
        courseGuid: data?.course?.guid,
        contentGuid: data?.content?.guid,
        evaluationGuid: data?.item?.guid,
      },
    };
  }
  return {};
}

function getAssignedConfig(data) {
  return {
    pathKey: ROUTE_TYPES.UNIT,
    state: {
      courseGuid: data?.course?.guid,
      unitGuid: data?.section?.guid,
      lessonGuid: data?.lesson?.guid,
      contentGuid: data?.content?.guid,
    },
  };
}
