import * as calendars from '../../_core/store/calendar.duck';
import * as appInfo from './ducks/appInfo.duck.js';
import * as contents from './ducks/contents.duck';
//import * as contentTypes from './ducks/contentTypes.duck';
//import * as countries from './ducks/countries.duck';
//import * as educationLevels from './ducks/educationLevels.duck';
import * as i18n from './ducks/i18n';
import * as lemonadeTest from './ducks/lemondateTest.duck';
//import * as lessons from './ducks/lessons.duck';
import * as licenses from './ducks/licenses.duck';
//import * as programs from './ducks/programs.duck';
import * as saml from './ducks/saml.duck';
//import * as schemes from './ducks/schemes.duck';
import * as tests from './ducks/tests.duck';
import * as ui from './ducks/ui.duck';
import * as users from './ducks/users.duck';

//Export reducer duck
export {
  i18n,
  //schemes,
  //educationLevels,
  //lessons,
  //contentTypes,
  contents,
  ui,
  tests,
  appInfo,
  saml,
  calendars,
  users,
  licenses,
  lemonadeTest,
};
